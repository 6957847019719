import { Member } from "@leanspace/js-client/dist/types/Teams";
import Chip from "@mui/material/Chip";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import {
  useDeleteMember,
  useDisableMember,
  useEnableMember,
  useMembers,
  useResetPassword,
} from "Hooks/members";
import { useSnackbar } from "notistack";
import React, { useState } from "react";

import Confirmation from "./Confirmation";

enum OperationType {
  DELETE = "Delete",
  DISABLE = "Disable",
  ENABLE = "Enable",
  RESET = "Reset",
  NONE = "None",
}

interface MembersListProps {
  tenant: string;
  name: string;
}
const MembersDisplayPerTenant: React.FC<
  React.PropsWithChildren<MembersListProps>
> = ({ tenant, name }) => {
  const [isConfirming, setIsConfirming] = useState(false);
  const [displayText, setDisplayText] = useState("");
  const [operationType, setOperationType] = useState(OperationType.NONE);
  const [selectedMember, setSelectedMember] = useState<Member>();
  const [selectedTenant, setSelectedTenant] = useState<string>();
  const { enqueueSnackbar } = useSnackbar();

  const { data } = useMembers({
    sort: "createdAt,ASC",
    tenant,
    query: `.*${name}.*`,
    size: 100,
  });

  const members = data?.content || [];

  const { mutateAsync: disableMemberByTenant } = useDisableMember();

  const disableMember = async (member: Member, tenant: string) => {
    try {
      await disableMemberByTenant({ tenant, memberId: member.id });
      enqueueSnackbar(`Disabled member ${member.name} in ${tenant}.`, {
        variant: "success",
      });
    } catch (error) {
      console.error(error);
      enqueueSnackbar(`Unable to disable member ${member.name} in ${tenant}.`, {
        variant: "error",
      });
    }
  };

  const { mutateAsync: enableMemberByTenant } = useEnableMember();

  const enableMember = async (member: Member, tenant: string) => {
    try {
      await enableMemberByTenant({ tenant, memberId: member.id });
      enqueueSnackbar(`Enabled member ${member.name} in ${tenant}.`, {
        variant: "success",
      });
    } catch (error) {
      console.error(error);
      enqueueSnackbar(`Unable to enable member ${member.name} in ${tenant}.`, {
        variant: "error",
      });
    }
  };

  const { mutateAsync: resetPassword } = useResetPassword();

  const resetPasswordForMember = async (member: Member, tenant: string) => {
    try {
      await resetPassword({ tenant, memberId: member.id });
      enqueueSnackbar(
        `Reset the password for member ${member.name} in ${tenant}.`,
        { variant: "success" }
      );
    } catch (error) {
      console.error(error);
      enqueueSnackbar(
        `Unable to reset the password for member ${member.name} in ${tenant}.`,
        {
          variant: "error",
        }
      );
    }
  };

  const { mutateAsync: deleteMemberByTenant } = useDeleteMember();

  const deleteMember = async (member: Member, tenant: string) => {
    try {
      await deleteMemberByTenant({ tenant, memberId: member.id });
      enqueueSnackbar(`Deleted member ${member.name} in ${tenant}.`, {
        variant: "success",
      });
    } catch (error) {
      console.error(error);
      enqueueSnackbar(`Unable to delete member ${member.name} in ${tenant}.`, {
        variant: "error",
      });
    }
  };

  const onSubmitFunction = async (member: Member | undefined, tenant: string | undefined) => {
    if (!member || !tenant) {
      enqueueSnackbar(`Member or tenant is not defined.`, {
        variant: "error",
      });
      return;
    }
    switch (operationType) {
      case OperationType.DELETE:
        await deleteMember(member, tenant);
        break;
      case OperationType.DISABLE:
        await disableMember(member, tenant);
        break;
      case OperationType.ENABLE:
        await enableMember(member, tenant);
        break;
      case OperationType.RESET:
        await resetPasswordForMember(member, tenant);
        break;
      default:
        enqueueSnackbar(`Unexpected operation type.`, {
          variant: "error",
        });
        break;
    }
  };

  return (
    <>
      {members.map((member: Member) => {
        return (
          <TableRow key={member.id}>
            <TableCell>{tenant}</TableCell>
            <TableCell>{member.name}</TableCell>
            <TableCell>{member.email}</TableCell>
            <TableCell>
              <Chip
                label={member.state}
                color={member.state === "ACTIVE" ? "success" : "error"}
                variant="outlined"
              />
            </TableCell>
            <TableCell>
              <Chip
                label={member.state === "ACTIVE" ? "Disable" : "Enable"}
                color={member.state === "ACTIVE" ? "error" : "success"}
                variant="outlined"
                onClick={() => {
                  setSelectedMember(member);
                  setSelectedTenant(tenant);
                  setIsConfirming(true);
                  setDisplayText(
                    `Are you sure you want to ${
                      member.state === "ACTIVE" ? "disable" : "enable"
                    } ${member.name} in ${tenant}?`
                  );
                  setOperationType(
                    member.state === "ACTIVE"
                      ? OperationType.DISABLE
                      : OperationType.ENABLE
                  );
                }}
              />
              <Chip
                label="Reset"
                color="warning"
                variant="outlined"
                onClick={() => {
                  setSelectedMember(member);
                  setSelectedTenant(tenant);
                  setIsConfirming(true);
                  setDisplayText(
                    `Are you sure you want to reset the password for ${member.name} in ${tenant}?`
                  );
                  setOperationType(OperationType.RESET);
                }}
              />
              <Chip
                label="Delete"
                color="error"
                variant="outlined"
                onClick={() => {
                  setSelectedMember(member);
                  setSelectedTenant(tenant);
                  setIsConfirming(true);
                  setDisplayText(
                    `Are you sure you want to delete ${member.name} from ${tenant}?`
                  );
                  setOperationType(OperationType.DELETE);
                }}
              />
              <Confirmation
                open={isConfirming}
                onClose={() => {
                  setSelectedMember(undefined);
                  setSelectedTenant(undefined);
                  setIsConfirming(false);
                  setOperationType(OperationType.NONE);
                  setDisplayText("");
                }}
                displayText={displayText}
                operationName={operationType}
                onSubmitFunction={onSubmitFunction.bind(null, selectedMember, selectedTenant)}
              />
            </TableCell>
          </TableRow>
        );
      })}
    </>
  );
};

export default MembersDisplayPerTenant;
