import { useLeanspaceAPI } from "@leanspace/js-client/dist/react";
import { Members } from "@leanspace/js-client/dist/services/Teams";
import { useMutation, useQuery, useQueryClient } from "react-query";

export const useMembers = (...args: Parameters<Members["getByTenant"]>) => {
  const {
    roles: { members },
  } = useLeanspaceAPI();
  return useQuery(["member", ...args], () => members.getByTenant(...args));
};
export const useMember = (...params: Parameters<Members["get"]>) => {
  const {
    roles: { members },
  } = useLeanspaceAPI();
  return useQuery(["member-details", ...params], () => members.get(...params));
};

export const useResetPassword = () => {
  const {
    roles: { members },
  } = useLeanspaceAPI();
  return useMutation({
    mutationFn: (...params: Parameters<Members["resetTenantMemberPassword"]>) =>
      members.resetTenantMemberPassword(...params),
  });
};

export const useEnableMember = () => {
  const {
    roles: { members },
  } = useLeanspaceAPI();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (...params: Parameters<Members["enableTenantMember"]>) =>
      members.enableTenantMember(...params),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["member"] }),
  });
};

export const useDisableMember = () => {
  const {
    roles: { members },
  } = useLeanspaceAPI();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (...params: Parameters<Members["disableTenantMember"]>) =>
      members.disableTenantMember(...params),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["member"] }),
  });
};

export const useDeleteMember = () => {
  const {
    roles: { members },
  } = useLeanspaceAPI();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (...params: Parameters<Members["deleteTenantMember"]>) =>
      members.deleteTenantMember(...params),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["member"] }),
  });
};
