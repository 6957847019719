import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import React from "react";

interface ConfirmationProps {
  open: boolean;
  onClose: () => void;
  displayText: string;
  operationName: string;
  onSubmitFunction: () => Promise<void>;
}

const Confirmation: React.FC<React.PropsWithChildren<ConfirmationProps>> = ({
  open,
  onClose,
  operationName,
  displayText,
  onSubmitFunction,
}) => {
  const confirmation = async () => {
    await onSubmitFunction();
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{displayText}</DialogTitle>

      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={confirmation}>{operationName}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default Confirmation;
