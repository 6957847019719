import { Tenant } from "@leanspace/js-client/dist/types/Tenants";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import UserContext from "Contexts/UserContext";
import { getFreshJWT } from "Hooks/Cognito";
import { useTriggerWorkflow } from "Hooks/Github";
import { useSnackbar } from "notistack";
import React, { SyntheticEvent, useContext, useState } from "react";

import BuildInProgressCheck from "./BuildInProgressCheck";

interface DeleteTenantProps {
  tenant: Tenant;
}

const DeleteTenant: React.FC<React.PropsWithChildren<DeleteTenantProps>> = ({
  tenant,
}) => {
  const { env } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [confirmationIsCorrect, setConfirmationIsCorrect] = useState(false);
  const { mutateAsync: triggerWorkflow } = useTriggerWorkflow();
  const [hasTriggeredTenantRemoval, setHasTriggeredTenantRemoval] =
    useState(false);

  const onCloseDialog = () => setIsConfirmDialogOpen(false);

  const verifyConfirmationText = (event: SyntheticEvent) => {
    const input = (event.target as HTMLInputElement).value;

    if (input === tenant.name) setConfirmationIsCorrect(true);
    else setConfirmationIsCorrect(false);
  };

  const deleteTenant = async (event: SyntheticEvent) => {
    try {
      const freshToken = await getFreshJWT();
      setHasTriggeredTenantRemoval(false);
      event.preventDefault();
      await triggerWorkflow({
        workflow_id: "deleteTenant.yml",
        repo: "leanspace-infra",
        ref: "main",
        inputs: {
          aws_env_name: env === "DEV" ? "develop" : env.toLowerCase(),
          tenant_name: tenant.name,
          "leanspace-username": "ignored-username",
          "leanspace-password": "ignored-username",
          "leanspace-token": freshToken ?? "",
        },
      });
      setHasTriggeredTenantRemoval(true);
      enqueueSnackbar("The deletion has started.", { variant: "success" });
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Failed to delete tenant.", {
        variant: "error",
      });
    }
  };

  return (
    <Box
      sx={{
        padding: 2,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <Typography>
        Permanently delete this tenant. This action cannot be undone.
      </Typography>
      <Button
        variant="contained"
        color="error"
        onClick={() => setIsConfirmDialogOpen(true)}
      >
        delete
      </Button>

      <Dialog open={isConfirmDialogOpen} onClose={onCloseDialog}>
        <DialogTitle>
          Permanently deleting tenant <em>{tenant.name}</em>
        </DialogTitle>
        {hasTriggeredTenantRemoval ? (
          <>
            <DialogContent>
              <DialogContentText>
                The tenant removal is in progress. You can start following the
                progress in a couple of seconds.
              </DialogContentText>
              <DialogContentText>
                If the tenant had any monitors scheduled, please run the
                <a
                  href="https://github.com/leanspace/leanspace-infra/blob/main/utils/manual/delete_quartz_job.sh"
                  target="_blank"
                  rel="noreferrer"
                >
                  clean up script
                </a>
                after the tenant removal process is completed.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={onCloseDialog}>Close</Button>
              <Button
                href={`https://github.com/leanspace/leanspace-infra/actions/workflows/deleteTenant.yml`}
                color="success"
              >
                Follow progress
              </Button>
            </DialogActions>
          </>
        ) : (
          <>
            <DialogContent>
              <BuildInProgressCheck />
            </DialogContent>
            <DialogContent>
              <DialogContentText>
                This will delete the tenant and all its data. The tenant cannot
                be restored.
              </DialogContentText>

              <DialogContentText>
                To confirm, please type <em>{tenant.name}</em> in the box below.
              </DialogContentText>
            </DialogContent>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="confirmation"
                label="Type the name of the tenant to confirm"
                type="text"
                fullWidth
                variant="standard"
                required
                onChange={verifyConfirmationText}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={onCloseDialog}>Cancel</Button>
              <Button
                color="error"
                disabled={!confirmationIsCorrect}
                onClick={deleteTenant}
              >
                Yes, delete now
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </Box>
  );
};

export default DeleteTenant;
