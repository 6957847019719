import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { RestEndpointMethodTypes } from "@octokit/plugin-rest-endpoint-methods";
import {
  usePollWorkflowRunUntilCompleted,
  useTriggerWorkflow,
  useWorkflowRuns,
} from "Hooks/Github";
import { useSnackbar } from "notistack";
import React, { SyntheticEvent, useState } from "react";
import { ArrowDown } from "react-feather";
import { ENV_FULL_NAME } from "Types/env";

import MpsDemoScriptStepper from "./Stepper";

enum MPS_SCRIPT_MODE {
  STANDARD = "Standard",
  GMAT_INTEGRATION = "GMAT integration",
  NO_ORBITAL_EVENTS = "No orbital events",
  SETUP_EXOTRAIL_CONNECTION = "Setup exotrail connection",
  CLEANUP = "Cleanup",
}

const mpsModeOptions = [
  {
    value: MPS_SCRIPT_MODE.STANDARD,
    tooltip:
      "Orbital events, contacts, activities, draft plan and committed plan are created and a resource propagation is triggered for each plan",
  },
  {
    value: MPS_SCRIPT_MODE.GMAT_INTEGRATION,
    tooltip:
      "GMAT script integration mode, STANDARD but no orbital events are generated and an existing DRAFT plan is used",
  },
  {
    value: MPS_SCRIPT_MODE.NO_ORBITAL_EVENTS,
    tooltip: "STANDARD but no orbital events nor contacts are generated",
  },
  {
    value: MPS_SCRIPT_MODE.SETUP_EXOTRAIL_CONNECTION,
    tooltip: "Only a connection to exotrail will be created",
  },
  {
    value: MPS_SCRIPT_MODE.CLEANUP,
    tooltip:
      "Activities related to the satellite are deleted and all the plans with the given tag are deleted",
  },
];

const MpsDemoScript: React.FC = ({}) => {
  const repositoryName = "mps-demo-script";
  const workflowId = "build-and-run.yml";

  const { enqueueSnackbar } = useSnackbar();
  const [environment, setEnvironment] = useState<ENV_FULL_NAME>(
    ENV_FULL_NAME.DEV
  );

  const [mode, setMode] = useState<MPS_SCRIPT_MODE>(MPS_SCRIPT_MODE.STANDARD);

  const [isWorkflowTriggetButtonDisabled, setIsWorkflowTriggetButtonDisabled] =
    useState<boolean>(false);

  const { mutateAsync: triggerWorkflow } = useTriggerWorkflow();
  const { data: workflowList, refetch: refetchWorkflowRuns } = useWorkflowRuns(
    repositoryName,
    workflowId
  );

  const workflowRunId = findCorrectWorkflow(workflowList, workflowId);

  const { data: run, dataUpdatedAt: lastRunFetch } =
    usePollWorkflowRunUntilCompleted(repositoryName, workflowRunId);

  const onRunLaunch = async (e: SyntheticEvent) => {
    e.preventDefault();

    const form = e.target as HTMLFormElement;

    await triggerWorkflow({
      workflow_id: workflowId,
      repo: repositoryName,
      ref: "main",
      inputs: {
        token: form.leanspacetoken.value,
        environment: environment,
        exotrailUsername: form.exotrailusername.value,
        exotrailPwd: form.exotrailpassword.value,
        satelliteTag: form.satellitetag.value,
        groundStationsTag: form.groundstationstag.value,
        orbitTag: form.orbittag.value,
        activityTag: form.activitytag.value,
        planTag: form.plantag.value,
        mode: Object.keys(MPS_SCRIPT_MODE)[
          Object.values(MPS_SCRIPT_MODE).indexOf(mode)
        ], // get the key of the enum
      },
    });
    setIsWorkflowTriggetButtonDisabled(true);
    timeout5s();
    enqueueSnackbar(
      "MPS demo script application has been requested, its state will soon be displayed in the progress bar",
      { variant: "success" }
    );
  };

  const timeout5s = () => {
    setTimeout(() => setIsWorkflowTriggetButtonDisabled(false), 5000);
  };

  const onWorkflowRefetch = () => {
    refetchWorkflowRuns();
  };

  return (
    <>
      <Box
        sx={{
          padding: 2,
        }}
      >
        <Typography sx={{ padding: 2 }} variant="h5">
          MPS Demo script
        </Typography>
        <Typography sx={{ padding: 2 }}>Connection settings</Typography>
        <form onSubmit={onRunLaunch}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              padding: 2,
            }}
          >
            <Tooltip title="Leanspace JWT token, found in the console (settings>API)">
              <TextField
                id="leanspacetoken"
                name="leanspacetoken"
                required
                label="Leanspace token"
                sx={{ paddingRight: 10 }}
              />
            </Tooltip>
            <Tooltip title="On which environment data is generated">
              <FormControl required sx={{ paddingRight: 10 }}>
                <FormLabel id="environment">Environment</FormLabel>
                <RadioGroup
                  row
                  defaultValue={ENV_FULL_NAME.DEV}
                  onChange={(event) =>
                    setEnvironment(event.target.value as ENV_FULL_NAME)
                  }
                >
                  <FormControlLabel
                    value={ENV_FULL_NAME.DEV}
                    control={<Radio />}
                    label={ENV_FULL_NAME.DEV}
                    labelPlacement="bottom"
                  />
                  <FormControlLabel
                    value={ENV_FULL_NAME.PROD}
                    control={<Radio />}
                    label={ENV_FULL_NAME.PROD}
                    labelPlacement="bottom"
                  />
                </RadioGroup>
              </FormControl>
            </Tooltip>

            <FormControl required>
              <FormLabel id="mpsScriptMode">MPS Script Mode</FormLabel>
              <RadioGroup
                row
                defaultValue={MPS_SCRIPT_MODE.STANDARD}
                onChange={(event) =>
                  setMode(event.target.value as MPS_SCRIPT_MODE)
                }
              >
                {mpsModeOptions.map((option) => (
                  <Tooltip
                    key={option.value}
                    title={
                      <Typography variant="body2">{option.tooltip}</Typography>
                    }
                  >
                    <FormControlLabel
                      value={option.value}
                      control={<Radio />}
                      label={option.value}
                      labelPlacement="bottom"
                    />
                  </Tooltip>
                ))}
              </RadioGroup>
            </FormControl>
          </Box>
          <Accordion>
            <AccordionSummary expandIcon={<ArrowDown />}>
              Optional parameters
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ padding: 2 }}>
                Exotrail settings override
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  padding: 2,
                }}
              >
                <Tooltip title="If wanted, override the exotrail API username">
                  <TextField
                    id="exotrailusername"
                    name="exotrailusername"
                    label="Exotrail Username"
                    sx={{ paddingRight: 10, marginTop: 3 }}
                  />
                </Tooltip>
                <Tooltip title="If wanted, override the exotrail API password">
                  <TextField
                    id="exotrailpassword"
                    name="exotrailpassword"
                    label="Exotrail password"
                    sx={{ paddingRight: 10, marginTop: 3 }}
                  />
                </Tooltip>
              </Box>
              <Typography sx={{ padding: 2 }}>Tag settings</Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  padding: 2,
                }}
              >
                <Tooltip title="Tag to use to find the unique satellite to apply the script to">
                  <TextField
                    id="satellitetag"
                    name="satellitetag"
                    defaultValue={"mpslite"}
                    label="Satellite tag"
                    sx={{ paddingRight: 10, marginTop: 3 }}
                  />
                </Tooltip>
                <Tooltip title="Tag to use to find the ground stations eligible for contacts">
                  <TextField
                    id="groundstationstag"
                    name="groundstationstag"
                    defaultValue={"mpslite"}
                    label="Ground stations tag"
                    sx={{ paddingRight: 10, marginTop: 3 }}
                  />
                </Tooltip>
                <Tooltip title="Tag added to all activities created by the script">
                  <TextField
                    id="activitytag"
                    name="activitytag"
                    defaultValue={"mpslite"}
                    label="Activity tag"
                    sx={{ paddingRight: 10, marginTop: 3 }}
                  />
                </Tooltip>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  padding: 2,
                }}
              >
                <Tooltip title="Tag to use to find the orbit of the satellite used for tle propagation and orbital events generation">
                  <TextField
                    id="orbittag"
                    name="orbittag"
                    defaultValue={"mpslite"}
                    label="Orbit tag"
                    sx={{ paddingRight: 10, marginTop: 3 }}
                  />
                </Tooltip>
                <Tooltip title="Tag to use to find the plan to insert the activities on (note: editable and use only in GMAT_INTEGRATION mode)">
                  <TextField
                    id="plantag"
                    name="plantag"
                    defaultValue={"mpslite"}
                    label="Plan tag"
                    disabled={mode !== MPS_SCRIPT_MODE.GMAT_INTEGRATION}
                    sx={{ marginTop: 3 }}
                  />
                </Tooltip>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Box sx={{ padding: 2, textAlign: "right" }}>
            <Button
              variant="contained"
              sx={{ marginRight: 10 }}
              onClick={onWorkflowRefetch}
            >
              Re-fetch last workflow run
            </Button>
            <Button
              disabled={isWorkflowTriggetButtonDisabled}
              variant="contained"
              type="submit"
            >
              Execute script
            </Button>
          </Box>
        </form>
      </Box>

      {run && <MpsDemoScriptStepper run={run} lastRunFetch={lastRunFetch} />}
    </>
  );
};

const findCorrectWorkflow = (
  workflowList:
    | RestEndpointMethodTypes["actions"]["listWorkflowRunsForRepo"]["response"]
    | undefined,
  workflowId: string
) => {
  if (!workflowList) {
    return undefined;
  }

  const workflowToListenOnList = workflowList.data.workflow_runs.filter((wf) =>
    wf.path.includes(workflowId)
  );

  if (workflowToListenOnList.length > 0) {
    // take the most recent one if multiple
    return workflowToListenOnList.sort(
      (a, b) =>
        Date.parse(b.run_started_at as string) -
        Date.parse(a.run_started_at as string)
    )[0].id;
  }
  return undefined;
};

export default MpsDemoScript;
