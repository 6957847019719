import { useLeanspaceAPI } from "@leanspace/js-client/dist/react";
import {
  DeliveryRequests,
  DeliveryResponses,
} from "@leanspace/js-client/dist/services/UsageMetrics";
import dayjs from "dayjs";
import { useMutation, useQuery, useQueryClient } from "react-query";

export const useUsageMetrics = (tenant: string) => {
  const {
    usageMetrics: { usageMetrics },
  } = useLeanspaceAPI();
  return useQuery(["usage-metrics"], () =>
    usageMetrics.accumulatedByTenant({
      tenant,
      measuredFrom: dayjs().startOf("month").format("YYYY-MM-DD"),
      measuredTo: dayjs().format("YYYY-MM-DD"),
    })
  );
};

export const useDeliveryRequests = (
  ...args: Parameters<DeliveryRequests["getAll"]>
) => {
  const {
    usageMetrics: { deliveryRequests },
  } = useLeanspaceAPI();
  return useQuery(["delivery-requests"], () =>
    deliveryRequests.getAll(...args)
  );
};

export const useDeliveryResponses = (
  ...args: Parameters<DeliveryResponses["getAll"]>
) => {
  const {
    usageMetrics: { deliveryResponses },
  } = useLeanspaceAPI();
  return useQuery(["delivery-responses"], () =>
    deliveryResponses.getAll(...args)
  );
};

export const useRequestDeliveryMutation = () => {
  const {
    usageMetrics: { deliveryRequests },
  } = useLeanspaceAPI();
  const queryClient = useQueryClient();
  return useMutation(deliveryRequests.create.bind(deliveryRequests), {
    onSuccess: () => {
      queryClient.invalidateQueries(["delivery-requests"]);
    },
  });
};
